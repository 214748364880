<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="routers"
          sort-by="inserted_at"
          item-key="id"
          :headers="headers"
          :loading="reportStore.loading"
          :sort-desc="true"
          :provider="provider"
          :footer-props="{
            'items-per-page-options': [100, 500, 1000],
          }"
          show-select
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              dark
              color="error"
              small
              :loading="reportStore.loading"
              @click="deleteAll"
            >
              Delete
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-1"
              small
              @click="addNewRouter"
            >
              New Router
            </v-btn>
            <RouterDialog v-model="routerDialog" :edited-router="editedItem" />
          </template>
          <template #item.actions="{ item }">
            <ERow justify="center" align="center">
              <!-- EDIT -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="editRouter(item)"
                  >
                    <v-icon small> fa-pencil-alt </v-icon>
                  </v-btn>
                </template>
                <span>Edit the router</span>
              </v-tooltip>
            </ERow>
          </template>
          <template #item.serialNumber="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="openRouterSummaryDialog(item)"
            >
              {{ item.serialNumber }}
            </span>
            <OpenInNewWindowButton
              :target-link="`${item.rmsUrl}`"
              hint="Open In RMS"
            />
          </template>
          <template #item.cameras="{ item }">
            <CamerasColumn
              :item="item"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </template>
          <template #item.sims="{ item }">
            <SimsColumn
              :item="item"
              @open-sms-history-dialog="openSmsHistoryDialog"
            />
          </template>
          <template #item.projects="{ item }">
            <div v-if="item.sims && item.sims.length > 0">
              <span
                v-for="(project, index) in item.projects"
                :key="`${project.id}${index}`"
                class="cursor-pointer primary--text"
                @click="projectStore.openDialog(project.exid)"
              >
                {{
                  index !== item.projects.length - 1
                    ? `${project.name},`
                    : project.name
                }}
              </span>
            </div>
            <span v-else>-</span>
          </template>
          <template #item.billPayers="{ item }">
            <div v-if="item.billPayers && item.billPayers.length > 0">
              <span
                v-for="(billPayer, index) in item.billPayers"
                :key="billPayer.name"
              >
                {{
                  index !== item.billPayers.length - 1
                    ? `${billPayer.name},`
                    : billPayer.name
                }}
              </span>
            </div>
            <span v-else>-</span>
          </template>
          <template #item.lastSeen="{ item }">
            <div v-if="item.battery">
              <span
                class="primary--text cursor-pointer"
                @click="openBatteryReadingsDialog(item.cameras[0].exid)"
              >
                {{ item.battery.lastVoltage }}V ({{
                  $moment(item.battery.lastSeen).fromNow()
                }})
              </span>
            </div>
            <div v-else>-</div>
          </template>
          <template #item.lastSmsAt="{ item }">
            {{ formatDate(item.lastSmsAt) }}
          </template>
          <template #item.powerType="{ item }">
            {{ snakeCaseToTitleCase(item.powerType) }}
          </template>
          <template #item.lastSms="{ item }">
            <EReadMore :content="item.lastSms" :max-length="45" />
          </template>
          <template #item.status="{ item }">
            <span v-if="isActiveRouter(item.status)" class="green--text">
              Active
            </span>
            <span v-else class="red--text"> Inactive </span>
          </template>
        </ReportTable>
        <RouterSummaryDialog
          :show-dialog="showRouterSummaryDialog"
          :router="selectedRouter"
          :router-types="routerTypes"
          @closed="closeRouterSummaryDialog()"
        />
        <SmsHistoryDialog
          v-model="showSmsHistoryDialog"
          :sim="selectedSimNumber"
          :selected-sim="selectedSim"
        />
        <BatteryReadingsDialog
          :id="batteryId"
          :show-dialog="showBatteryReadingsDialog"
          @closed="closeBatteryReadingsDialog()"
        />
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import RoutersHeader from "@/components/routers/routersHeaders"
import filterFields from "@/components/routers/routersSearchFilters"
import RouterDialog from "@/components/routers/RouterDialog"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog"
import RouterSummaryDialog from "@/components/routers/RouterSummaryDialog"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import CamerasColumn from "@/components/CamerasColumn"
import SimsColumn from "@/components/SimsColumn"
import BatteryReadingsDialog from "@/components/batteries/BatteryReadingsDialog"
import ReportTable from "@/components/ReportTable"
import { camelizeKeys } from "humps"
import Vue from "vue"
import { RouterStatus } from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useProjectStore } from "@/stores/projects"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  components: {
    ReportTable,
    RouterDialog,
    SmsHistoryDialog,
    RouterSummaryDialog,
    OpenInNewWindowButton,
    CamerasColumn,
    SimsColumn,
    BatteryReadingsDialog,
  },
  data: () => {
    return {
      isDeletionFailed: false,
      headers: RoutersHeader,
      editedItem: {},
      selectedRouter: {},
      cameras: [],
      filterFields: {},
      billPayers: [],
      routerTypes: [],
      routerDialog: false,
      showSmsHistoryDialog: false,
      selectedSimNumber: "",
      selectedSim: null,
      showBatteryReadingsDialog: false,
      showRouterSummaryDialog: false,
      batteryId: 0,
    }
  },
  head() {
    return {
      title: "Admin - Routers",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore, useProjectStore),
    provider() {
      return AdminApi.routers.getRouters
    },
  },
  async created() {
    this.selectedRouter = camelizeKeys(this.getSubQueryParams())
    let { items } = await AdminApi.routers.getRouters({
      params: {
        sort: "inserted_at|desc",
        limit: 100,
        page: 1,
        ...this.selectedRouter,
      },
    })
    let router = items?.find(
      (item) => item.serialNumber == this.selectedRouter?.serialNumber
    )
    if (router) {
      this.openRouterSummaryDialog(router)
    }
    await this.getBillPayers()
    await this.getRouterTypes()
    this.filterFields = filterFields(this)
  },
  methods: {
    isActiveRouter(status: RouterStatus) {
      return status === RouterStatus.Active
    },
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one router!")

        return
      }
      if (
        await this.$confirmDialog.open({
          title: "Delete router(s) ?",
          message: "This will permanently delete selected router(s)!",
        })
      ) {
        this.reportStore.loading = true
        for (const router of this.reportStore.selectedItems) {
          await this.deleteRouter(router)
        }
        this.reportStore.loading = false
        this.reportStore.selectedItems = []
        if (this.isDeletionFailed) {
          this.isDeletionFailed = false

          return
        }
        this.$notifications.success("Router(s) deleted successfully.")
      }
    },
    async deleteRouter(router) {
      try {
        await AdminApi.routers.deleteRouter(router.id)
        this.reportStore.items = this.reportStore.items.filter(
          (item) => item.id !== router.id
        )
      } catch (error) {
        this.isDeletionFailed = true
        this.$notifications.error({ text: "Delete router failed!", error })
      }
    },
    openRouterSummaryDialog(router) {
      this.showRouterSummaryDialog = true
      this.selectedRouter = router
    },
    openBatteryReadingsDialog(id) {
      this.showBatteryReadingsDialog = true
      this.batteryId = id
    },
    closeBatteryReadingsDialog() {
      this.showBatteryReadingsDialog = false
    },
    closeRouterSummaryDialog() {
      this.showRouterSummaryDialog = false
      this.selectedRouter = {}
    },
    async getBillPayers() {
      try {
        this.billPayers = await AdminApi.sims.getBillPayers()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load bill payers!",
          error,
        })
      }
    },
    async getRouterTypes() {
      try {
        this.routerTypes = await AdminApi.routers.getRouterTypes()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load router types!",
          error,
        })
      }
    },
    editRouter(item) {
      this.editedItem = item
      this.routerDialog = true
    },
    addNewRouter() {
      this.editedItem = {}
      this.routerDialog = true
    },
    openSmsHistoryDialog(item) {
      this.showSmsHistoryDialog = true
      this.selectedSimNumber = item?.number
      this.selectedSim = item
    },
  },
})
</script>
